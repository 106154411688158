@import './material.theme';
@import '~font-awesome/css/font-awesome.css';
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';

// __<<ngThemingMigrationEscapedComment0>>__

// @font-face {
//   font-family: Nunito-Black;
//   src: url(fonts/Nunito-Black.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-ReguBlackItaliclar;
//   src: url(fonts/Nunito-BlackItalic.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-Bold;
//   src: url(fonts/Nunito-Bold.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-BoldItalic;
//   src: url(fonts/Nunito-BoldItalic.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-ExtraBold;
//   src: url(fonts/Nunito-ExtraBold.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-ExtraBoldItalic;
//   src: url(fonts/Nunito-ExtraBoldItalic.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-ExtraLight;
//   src: url(fonts/Nunito-ExtraLight.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-ExtraLightItalic;
//   src: url(fonts/Nunito-ExtraLightItalic.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-Italic;
//   src: url(fonts/Nunito-Italic.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-Light;
//   src: url(fonts/Nunito-Light.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-LightItalic;
//   src: url(fonts/Nunito-LightItalic.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-Regular;
//   src: url(fonts/Nunito-Regular.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-SemiBold;
//   src: url(fonts/Nunito-SemiBold.ttf) format("opentype");
// }

// @font-face {
//   font-family: Nunito-SemiBoldItalic;
//   src: url(fonts/Nunito-SemiBoldItalic.ttf) format("opentype");
// }

// html,
// body,
// smione-app {
//   margin:0;
//   font-family: Nunito-Regular !important;
//   height: 100%;
// }

// smione-app{
//   display: flex;
//   flex-flow: column;
// }


// @import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Raleway';
    background-color: #fff;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
label,
input,
select,
textarea {
    font-family: 'Raleway';
    letter-spacing: 1px;
    margin: 0;
}

a,
button,
th,
td {
    font-family: 'Raleway';
    line-height: 26px;
}

a,
button {
    cursor: pointer;
    font-size: 16px;
}

p {
    font-family: 'Raleway';
    font-size: 14px;
    line-height: 20px;
}

a,
span,
td {
    font-family: 'Raleway';
    font-weight: 100;
}

td {
    color: #777;
}

ul {
    padding: 0;
    margin: 0;
}

/* List styles */
// li{
//     list-style: none;
// }
.loading-display-none {
    display: none;
}

.web-ul li,
.footer-part ul li,
.subnavigation li,
.web-side-nav ul li,
.web-menu li {
    list-style: none;
}

ul.a {
    list-style-type: upper-alpha;
}

ul.number {
    list-style-type: upper-roman;
}

ul.circle {
    list-style-type: circle;
}

ul.small {
    list-style-type: lower-alpha;
}

@media screen and (max-width: 600px) {
    ul {
        padding-left: 10px;
    }

    ol {
        padding-left: 10px;
    }
}

/* List styles */

a:hover {
    text-decoration: none !important;
}

// input{
//     font-family: 'Montserrat' !important;
// }

.font-raleway {
    font-family: 'Raleway' !important;
}

.font-mont {
    font-family: 'Montserrat' !important;
}

:focus {
    outline: none !important;
    box-shadow: 0 0 0 4px #bfdeff;
}

div:focus {
    box-shadow: none;
}

input:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 4px #bfdeff;
}

a:focus {
    outline: none;
    //box-shadow: none;
}

// button:focus{
//     box-shadow: 0 0 0 4px #bfdeff;
// }

.d_none {
    display: none !important;
}

.d_block {
    display: block !important;
}

.d_inlineblock {
    display: inline-block !important;
}

/* padding */
.p-0 {
    padding: 0 !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

/* padding */


/* margin */
.m-0 {
    margin: 0 !important;
}

.m-5 {
    margin: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 5px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

/* margin */

.btn-disable {
    opacity: 0.5;
}

.esign-agreement {
    table {
        margin: 20px 0px;
    }

    table thead tr th {
        font-family: 'Montserrat';
        // background: #ddd;
        font-size: 14px;
        color: #111212;
        font-weight: 600;
        text-align: center;
        padding: 5px 10px;
        border: 1px solid #999;
    }

    table thead~tbody tr:first-child td {
        background: transparent;
        font-weight: 100;
        text-align: left;
    }

    table tbody tr:first-child td {
        font-family: 'Montserrat';
        text-align: center;
        // background: #ddd;
        font-size: 14px;
        font-weight: 600;
        color: #111212;
        padding: 5px 10px;
        border: 1px solid #999;
    }

    table tbody tr td {
        font-family: 'Montserrat';
        font-size: 14px;
        color: #111212;
        font-weight: 100;
        padding: 5px 10px;
        border: 1px solid #999;
    }

    table:last-of-type thead tr th {
        background: #000;
        color: #fff;
        border: 1px solid #000;
    }

    table:last-of-type tbody tr:nth-child(1),
    table:last-of-type tbody tr:nth-child(4),
    table:last-of-type tbody tr:nth-child(6),
    table:last-of-type tbody tr:nth-child(9) {
        background: #ccc;
    }

    table:last-of-type tbody tr:nth-child(1) td,
    table:last-of-type tbody tr:nth-child(4) td,
    table:last-of-type tbody tr:nth-child(6) td,
    table:last-of-type tbody tr:nth-child(9) td {
        font-family: 'Montserrat';
        font-weight: 600;
        border-bottom: 1px solid #000;
    }
}


.blue-btn {
    background: #2E70FD;
    color: #fff;
    text-transform: uppercase;
    padding: 2px 15px;
    //height: 36px;
    font-weight: 600;
    border-radius: 100px;
    border: #2e70fd;
}

.green-btn {
    background: linear-gradient(178deg, #20E1A8, #00C188);
    color: #fff;
    text-transform: uppercase;
    min-width: 100px;
    height: 36px;
    max-width: 100%;
    padding: 2px 20px;
    font-weight: 600;
    border-radius: 100px;
    border: #00c188;
    opacity: 1;
    z-index: 999;
    transition: all ease-in-out 0.4s;
}

.green-btn:hover {
    background: linear-gradient(178deg, #00C188, #20E1A8);
}

.danger-btn {
    background: #ff0505;
    color: #fff;
    text-transform: uppercase;
    min-width: 100px;
    height: 36px;
    max-width: 100%;
    padding: 2px 15px;
    font-weight: 600;
    border-radius: 100px;
    opacity: 1;
    transition: all ease-in-out 0.4s;
}

.transparent-btn {
    border: 3px solid #21c897 !important;
    border: 3px solid var(--primary-color1) !important;
    border-radius: 50px;
    height: 36px;
    background-color: transparent;
    text-transform: uppercase;
    padding: 0px 15px !important;
    margin-bottom: 10px;
    font-weight: 600;
    transition: all ease-in-out 0.4s;
    z-index: 999;
}

.transparent-btn:hover {
    color: #fff !important;
    background-color: #00C188;
}

.ca-cancel {
    padding: 2px 15px;
    color: #06c78e;
    height: 36px;
    background: #fff;
    font-weight: 600;
    border: 3px solid #06c78e;
    border-radius: 100px;
    transition: all ease-in-out 0.4s;
}

.ca-cancel:hover {
    color: #fff;
    background-color: #06c78e;
}

.white-border-btn {
    border: 3px solid #fff !important;
    border-radius: 50px;
    background-color: transparent;
    text-transform: uppercase;
    padding: 0px 15px !important;
    margin-bottom: 10px;
    font-weight: 600;
}

.cr-heading h4,
.section-heading {
    font-weight: 600 !important;
    font-size: 32px !important;
}

.primary-color {
    color: #00C188 !important;
}

.form-label {
    margin: 0;
    color: #666;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.form-input {
    width: 100%;
    height: 36px;
    border: none;
    font-size: 12px;
    // text-transform: uppercase;
    background: #f1f5fd;
    border-radius: 4px;
    padding: 0 10px;
    color: #222323;
}

// ::placeholder{
//     font-family: 'Montserrat';
// }
.container-fluid {
    width: 98% !important;
    margin: 0px auto;
}

// Eshwar
.cdk-global-overlay-wrapper {
    background: #000000c2;
}

.mat-dialog-container {
    position: relative;
}

.grey-circle-graphic {
    display: none;
}

.bg-white {
    background-color: #fff;
}

.flex-btns {
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0;
    flex-wrap: wrap;
}

.custom-green-btn {
    padding: 4px 34px !important;
    height: 40px !important;
}

.green-btnDisable {
    background: #06c78e;
    opacity: 0.4;
    height: 36px;
    padding: 4px 15px;
    color: #fff;
    font-weight: 600;
    border: none;
    margin-left: 10px;
    border-radius: 100px;
}




.btn-close {
    opacity: 1;
    position: absolute;
    top: 15px;
    right: 30px;
    background: transparent;
    border: none;
    font-size: 24px;
}

.mat-dialog-container {
    padding: 30px !important;
    border-radius: 20px !important;
    box-shadow: 0 6px 20px 0 rgba(46, 112, 253, .25) !important;

}

.registration.bg-white {
    background: #e8f0fe !important;
}

.section-heading.header-text {
    // text-transform: uppercase;
    color: #000 !important;
}

.header-sub-text {
    font-size: 16px !important;
    color: #000 !important;
}

.registration .header-text {
    font-weight: 100 !important;
    //font-family: 'Montserrat';
}

.web-registration {
    display: flex;
    flex-wrap: wrap;
}

.step-container.web-registration {
    background: #fff !important;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 6px 20px 0 rgba(46, 112, 253, .25);
    min-height: 550px;
    max-height: 100%;
}

.web-registration .form-container {
    background: #fff;
}

.web-registration .form-header {
    font-weight: 600 !important;
    font-size: 20px !important;
    letter-spacing: 1px !important;
}

.register-img {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.register-footer {
    font-size: 14px;
    color: #333;
    line-height: 26px;
    padding: 30px;
    background: #e8f0fe;
}

.web-registration-img .content-2 {
    position: absolute;
    top: 30px;
    text-align: center;
    width: 100%;
}

.web-registration-form .form-row {
    padding: 5px 0;
}

.web-registration-form {
    padding: 30px 0 !important;
}

.highlight-image-register h4,
.highlight-image-register ul {
    padding-left: 30px;
}

//   .web-registration-form .form-input-box,
//   .web-registration-form .input-group{
//       width: 80% !important;
//   }
.highlight-image-register {
    position: absolute;
    top: 30px;
    text-align: center;
    width: 100%;
}

.suggestions-row li {
    list-style: disc;
    font-size: 14px;
}

.logo-image {
    width: 50%;
}

.suggestions-row li {
    list-style: disc;
    font-size: 14px;
}

.full-width {
    width: 100% !important;
}

.web-registration-form .form-row {
    padding: 5px 0;
}

// .step-container{
//     margin-top: 6%;
// }
.mat-option-text {
    text-overflow: initial !important;
    line-height: 20px !important;
    padding: 10px 0px !important;
}

.mat-select-panel .mat-option {
    height: auto !important;
    white-space: initial;
}

.mat-option {
    line-height: 30px !important;
}

@media (max-width: 992px) {
    .sc-header {
        flex-direction: column;
    }

    .modal.fade.web-modal.show {
        display: block !important;
    }
}


@media (max-width: 768px) {
    .web-registration-form {
        width: 100% !important;
        height: 100% !important;
    }

    .web-registration-img {
        width: 100% !important;
        height: 80vh !important;
    }

    .logo-image {
        position: absolute;
        left: 0;
        right: 0;
        top: 30em !important;
        width: 35%;
        margin: auto;
    }

    .step-container .form-container {
        width: 100% !important;
    }

    // .step-container{
    //     padding-top: 60px;
    // }
    .highlight-image-card,
    .highlight-image-alert {
        width: 50% !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
        margin: 30px auto !important;
    }

    .dashboard-web .container-fluid {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media(max-width:600px) {
    .mat-option-text {
        text-overflow: initial !important;
        line-height: 20px !important;
        padding: 10px 0px !important;
    }

    .mat-select-panel .mat-option {
        height: auto !important;
        white-space: initial;
    }

    .mat-option {
        line-height: 30px !important;
    }
}

@media (max-width: 500px) {
    .web-registration-form .form-row {
        flex-direction: column;
        margin: 20px 0;
    }

    .web-registration-form .form-row .form-group {
        max-width: 100%;
    }

    .web-registration-form .form-row label,
    .web-registration-form .form-row .form-group input {
        width: 100%;
        margin: 0;
    }

    .register-img {
        display: block;
    }

    .logo-image {
        top: 10em !important;
        margin: 0 auto !important;
    }
}


@media (max-width: 425px) {
    .highlight-image-register {
        margin-top: 10%;
    }
}

// 23-03-2021
/* table */
.web-table {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
}

.web-table thead tr th {
    text-transform: uppercase;
}

.web-table tbody tr td {
    font-family: 'Raleway';
}

.web-table tbody tr td,
.web-table thead tr th {
    font-size: 14px;
    color: #111212;
    padding: 16px 5px;
    border: none;
}

.web-table tbody tr td {
    font-family: 'Raleway';
    font-weight: 600;
}

.web-table tbody tr:nth-child(even) {
    background-color: #f1f5fd63;
}

.web-table tbody tr:nth-child(odd) {
    background-color: #F1F5FD;
}

/* table */
.webbox-design {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0 6px 20px 0 rgba(46, 112, 253, .25);
}

.np-bell span {
    color: #fff;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 6px;
    background: #ff0505;
    padding: 5px 5px;
    padding-bottom: 7px;
    text-align: center;
    border-radius: 6px;
}

.card-tabactive {
    color: #00C188 !important;
    font-weight: 600;
}

// .mat-form-field-flex,
// .mat-form-field-flex .mat-form-field-infix,
// .mat-form-field-wrapper{
//     padding: 0 !important;
//     margin: 0 !important;
//     line-height: 45px !important;
//     background-color: transparent !important;
//   }
//   .mat-form-field-underline{
//       width: 0 !important;
//   }
//   .mat-select-arrow-wrapper {
//     display: inline-block !important;
//     vertical-align: super !important;
// }
.agreement-content h4 {
    font-weight: 600;
    margin-bottom: 15px;
}

// .cdk-overlay-pane{
//     width: 50%;
//     margin: 0px auto;
//     max-height: 90vh;
// }

.font-bold {
    font-weight: 600;
}

.disabled {
    opacity: 0.4;
}

.mat-option-text {
    color: #111212 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    //font-family: 'Montserrat' !important;
}

.agreement-content {

    ul,
    ol {
        padding-left: 30px !important;
    }

    // ul li{
    //   list-style: disc  !important;
    // }
    // ol li{
    //   list-style: decimal  !important;
    // }
}


.alert-class1 {
    margin-top: 0px;
    display: block;
}

.alert-class2 {
    //margin-top: -50px;
    margin-top: 30px;
    display: block;
}

// theme
.green-btn,
.green-btn:hover {
    background: var(--primary-bg-btn) !important;
}

.green-btnDisable,
.green-btnDisable:hover {
    background: var(--primary-bg-btn) !important;
}

.primary-color {
    color: var(--primary-color1) !important;
}

.btn-calendar {
    color: var(--primary-color1);
}

.transparent-btn {
    border: 3px solid var(--primary-color1) !important;
}

bs-datepicker-container {
    z-index: 99999 !important;
}

.step-container.step-container-top-0 {
    margin-top: 10px !important;
}

.step-container {
    margin-top: 100px;
}

@media(max-width:1024px) {
    .step-container.step-container-top-0 {
        margin-top: 10px !important;
    }

    .step-container {
        margin-top: 100px !important;
    }
}

@media(max-width:768px) {
    .step-container.step-container-top-0 {
        margin-top: 10px !important;
    }

    .step-container {
        margin-top: 100px !important;
    }
}

@media(max-width:600px) {

    .agreement-content ul,
    .agreement-content ol {
        padding-left: 15px !important;
    }

    .web-cards-left {
        margin-top: 0px !important;
    }
}

.bottom-tray {
    text-align: left !important;

    .form-check {
        margin-left: 10px !important;
    }
}

@media(max-width: 991px) {
    smione-header-banner {
        height: auto !important;
    }
}

@media(max-width: 600px) {
    smione-header-banner {
        min-height: 150px !important;
        height: 150px !important;
        max-height: 200px;
    }
}


.quick-reg-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover-wrap {
    .content {
        padding: 8px !important;

        h6 {
            font-weight: 800 !important;
            margin-bottom: 8px !important;

            i {
                padding: 2px;
                cursor: pointer;
                font-size: 20px;
            }

            img {
                padding: 2px;
                cursor: pointer;
            }
        }

        p {
            font-size: 12px !important;
            color: #6a5e2e !important;
            font-weight: 600 !important;
            line-height: unset !important;
        }
    }

    .ribbon {
        background-color: #e4daad !important;
        padding: 8px !important;

        .ribbon-label {
            position: absolute;
            right: 5px;
            bottom: 18px;
        }
    }
}

b, strong {
    font-weight: bold;
}